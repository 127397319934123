<template>
    <div>
    <mercur-card class="mx-4 mb-3">
        <div slot="header">
            <h2 class="font-weight-normal">Add supplier information</h2>
        </div>
        <form class="form" is="json-form" :action="action" @success="saveSupplier" :values="values" @error="onError">
            <template slot-scope="form">
                <div slot="default">
                    <mercur-stepper :active-step.sync="steps.active" :is-full="true">
                        <mercur-step id="office" title="Add office data" subtitle="First Step" class="form--supplier-save mx-auto">
                            <location-office-fields :location="form.data.location" :v="$v.values.location" location-type="OFFICE" v-on:done="setDone('office', 'finance')"></location-office-fields>
                        </mercur-step>
                        <mercur-step id="finance" title="Add finance data" subtitle="Second Step" class="form--supplier-save mx-auto">
                            <location-finance-fields :location="form.data.office" :v="$v.values.office" v-on:done="setDone('finance', 'facility')"></location-finance-fields>
                        </mercur-step>
                        <mercur-step id="facility" title="Add facility data" subtitle="Third Step" class="form--supplier-save mx-auto">
                            <div class="row">
                                <div class="col-12 px-0 border mb-2">
                                    <div class="p-4 bg-primary">
                                        <h3 class="m-0 p-0 text-white">Facility</h3>
                                    </div>
                                     <div class="row p-4">
                                         <div class="col-6">

                                            {{form.data.location.locationName}}<br />
                                            {{form.data.location.address}} {{form.data.location.houseNumber}} {{form.data.location.houseNumberAdditional}}<br />
                                            {{form.data.location.addressAdditional}}<br />
                                            {{form.data.location.zipCode}} {{form.data.location.city}}<br />
                                            {{form.data.location.country}}
                                            <mercur-checkbox v-model="facility_same_address">
                                                 <h4 class="d-inline">This address is also a facility</h4>
                                            </mercur-checkbox><br>
                                            <span class="text-grey mt-2 "> Select if your office and facility are located at the same address</span>

                                        </div>
                                        <div class="col-6" v-if="facility_same_address">
                                            <div class="p-2">
                                                <mercur-input v-model="form.data.facility.locationName">
                                                    Facility name - optional
                                                </mercur-input>
                                                <mercur-input v-model="form.data.facility.emailAddress">
                                                    Email - leave blank to use {{form.data.location.emailAddress}}
                                                </mercur-input>
                                                <mercur-input v-model="form.data.facility.phone">
                                                    Phone number - leave blank to use {{form.data.location.phone}}
                                                </mercur-input>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                </div>
                                <div class="row" v-if="facility_same_address">
                                    <working-days-fields v-model="form.data.facility.workingDays" v-if="facility_same_address" :enable-cutoff-time="true" :enable-working-hours="true"></working-days-fields>
                                </div>
                        </mercur-step>
                    </mercur-stepper>

                    <p v-if="form.errors.message" v-text="form.errors.message"></p>

                    <div data-test="loading" class="loading-overlay" v-if="form.loading">
                        <mercur-progress-bar indeterminate></mercur-progress-bar>
                    </div>
                </div>
                <div class="text-right">
                    <mercur-button class="btn text-uppercase" @click="nextStep" v-if="shouldShowNextButton">Next step</mercur-button>
                    <mercur-button class="btn btn-yellow btn-raised text-uppercase" type="submit" :disabled="form.loading || $v.$invalid">Save supplier</mercur-button>
                </div>
            </template>
        </form>
    </mercur-card>
    </div>
</template>

<script>
import JsonForm from '@/components/utils/JsonForm'
import { mapGetters } from 'vuex'
import CONFIG from '@root/config'
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'

import LocationOfficeFields from '@/components/LocationOfficeFields'
import LocationFinanceFields from '@/components/LocationFinanceFields'
import WorkingDaysFields from '@/components/WorkingDaysFields'

export default {
    name: 'SupplierOnboardView',
    components: { LocationFinanceFields, LocationOfficeFields, WorkingDaysFields, JsonForm },
    mixins: [ validationMixin ],
    computed: {
        ...mapGetters('suppliers', ['getSupplierById']),
        action () {
            return CONFIG.API.ROUTES.SUPPLIERS.ONBOARD.replace('{supplierId}', this.supplierId)
        },
        shouldShowNextButton () {
            return this.steps.active === 'office' || this.steps.active === 'finance'
        },
    },

    data () {
        return {
            facility_same_address: false,
            values: {
                supplierStatus: this.$route.params.supplierStatus,
                supplierName: this.$route.params.supplierName,
                location: {
                    locationName: '',
                    emailAddress: '',
                    country: '',
                    address: '',
                    addressAdditional: '',
                    houseNumber: '',
                    houseNumberAdditional: '',
                    zipCode: '',
                    city: '',
                    phone: '',
                    certifications: [],
                    facilitySettings: {},
                },
                office: {
                    financeSettings: {
                        bankAccountNumber: '',
                        bankName: '',
                        bankSwiftCode: '',
                        chamberCommerceRegistrationNumber: '',
                        currency: '',
                        invoiceFrequencyUnit: '',
                        invoiceFrequencyValue: '',
                        paymentTermsUnit: '',
                        paymentTermsValue: '',
                        vatNumber: '',
                    },
                },
                facility: {
                    workingDays: [],
                },
            },
            steps: {
                active: 'office',

                office: true,
                finance: true,
                facility: true,

                errors: {
                    office: null,
                    finance: null,
                },
            },
            country: {
                countryName: '',
            },
            url: CONFIG.API.ROUTES.SUPPLIERS.GET.replace('{supplierId}', this.supplierId),
        }
    },

    validations: {
        values: {
            location: {
                locationName: {
                    required,
                },
                emailAddress: {
                    required,
                    email,
                },
                country: {
                    required,
                },
                address: {
                    required,
                },
                houseNumber: {
                    required,
                },
                zipCode: {
                    required,
                },
                city: {
                    required,
                },
            },
            office: {
                financeSettings: {
                    bankAccountNumber: {
                        required,
                    },
                    bankName: {
                        required,
                    },
                    bankSwiftCode: {
                        required,
                    },
                    chamberCommerceRegistrationNumber: {
                        required,
                    },
                    currency: {
                        required,
                    },
                    invoiceFrequencyUnit: {
                        required,
                    },
                    invoiceFrequencyValue: {
                        required,
                    },
                    paymentTermsUnit: {
                        required,
                    },
                    paymentTermsValue: {
                        required,
                    },
                    vatNumber: {
                        required,
                    },
                },
            },
        },
    },

    watch: {
        facility_same_address (value) {
            if (value) {
                this.values.facility = {
                    workingDays: [],
                }
            } else {
                this.$delete(this.values, 'facility')
            }
        },
    },

    methods: {
        nextStep () {
            if (this.steps.active === 'office') {
                this.$v.values.location.$touch()
                if (this.$v.values.location.$invalid) {
                    return
                }
                this.steps.active = 'finance'
                this.$v.reset()
            }

            if (this.steps.active === 'finance') {
                this.$v.values.office.$touch()
                if (this.$v.values.office.$invalid) {
                    return
                }
                this.steps.active = 'facility'
                this.$v.reset()
            }
        },
        setDone (id, index) {
            this.steps.errors[this.steps.active] = null
            this.$v.$touch()

            if (this.$v.$invalid) {
                this.steps[id] = false
                this.steps.errors[this.steps.active] = 'Please check the errors'

                return
            }

            this.steps[id] = true

            if (index) {
                this.steps.active = index
            }
        },
        saveSupplier (data) {
            if (data.message !== 'supplier.onboard.successful') {
                this.$root.$emit('notification:global', {
                    message: 'Supplier onboarding failed. Please check required fields.',
                    type: 'error',
                })
                return
            }

            this.$root.$emit('notification:global', {
                message: 'Supplier onboarding completed',
                actions: [
                    {
                        text: 'View supplier',
                        action () {
                            this.$router.push({
                                name: 'SupplierProducts',
                                params: {
                                    supplierId: this.supplierId,
                                },
                            })
                        },
                    },
                ],
            })

            this.$router.push({
                name: 'SupplierProducts',
                params: {
                    supplierId: this.supplierId,
                },
            })
        },
        onError (error) {
            this.$root.$emit('notification:global', {
                type: 'error',
                errors: error,
            })
        },
    },
}
</script>

<style scoped>
    .form--supplier-save {
        max-width: 800px;
        margin-top: 3rem;
    }

</style>
